#root {
  background-color: #2b3035;
  height: 200vh;
}

.App {
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  height: 100vh;
  width: 100%;
}

.header-row {
  width: inherit;
  text-align: center;
}

.chart-row {
  width: inherit;
  height: 70vh;

  #es-chart {
    width: inherit;
  }
}

.table-row {
  margin-top: 15px;
  width: inherit;
  height: 50vh;
  text-align: center;
  overflow: auto;
  color: whitesmoke;
}

.table {
  align-content: center;
  width: inherit;
  border: 2px solid darkgray;
  margin: 5px 5px 0 5px;

  th {
    border-bottom: 1px solid darkgray;
  }
}

.table-column {
  padding: 5px;
  text-align: center;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  display: block;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loginForm {
  color: white;
}